import {Injectable} from '@angular/core';

// Swagger Client API
import { ApiClientService } from './swagger';
import { LoginDataResponse, LoginRequest } from './swagger/models';


@Injectable()
export class AuthService {

    token: string;
    public error: string;

    constructor(private _apiClient: ApiClientService) {

    }

    // Realiza la peticion al servidor y guarda los datos del usuario
    login(credentials: LoginRequest) {

        return this._apiClient.login(credentials)
        .toPromise()
        .then( data => this.saveData(data.body.data) );
    }

    // guarda los datos del usuario
    saveData(data: LoginDataResponse) {
        localStorage.setItem('currentUser', JSON.stringify(data));
        localStorage.setItem('token', data.token);
        localStorage.setItem('condominium', JSON.stringify(data.condominiums[0]));
    }

    // elimina el token y el usuario
    logout() {
        localStorage.clear();
    }

    // Verifica si existe token
    isLogged() {
        const token = localStorage.getItem('token');

        if (token) {
          return true;
        }
        return false;
    }
}
