import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RecoverComponent } from './views/recover/recover.component';
import { RegisterComponent } from './views/register/register.component';
// import { AddNewsComponent } from './views/new/add/add-news.component';
// import { EditNewsComponent } from './views/new/edit/edit-news.component';
import { AddEventComponent } from './views/events/add/add-event.component';
import { EditEventComponent } from './views/events/edit/edit-event.component';
import { MobileRecoverComponent } from './views/mobilerecover/mobilerecover.component';
import { RolGuard } from './security/rol.guard';
import { BlockGuard } from './security/block.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'change/admin/:token',
    component: RecoverComponent,
    data: {
      title: 'Recover Page'
    },
    pathMatch: 'full',

  },
  {
    path: 'change/mobile/:token/:language',
    component: MobileRecoverComponent,
    data: {
      title: 'Genera contraseña'
    },
    pathMatch: 'full',

  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  // {
  //     path: 'agregarNoticia',
  //     component: AddNewsComponent,
  //     data: {
  //       title: 'Agregar Noticia'
  //     }
  // },
  // {
  //     path: 'editarNoticia',
  //     component: EditNewsComponent,
  //     data: {
  //       title: 'Editar Noticia'
  //     }
  // },
  {
      path: 'agregarEvento',
      component: AddEventComponent,
      data: {
        title: 'Agregar Evento'
      }
  },
  {
      path: 'editarEvento',
      component: EditEventComponent,
      data: {
        title: 'Editar Evento'
      }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: './views/base/base.module#BaseModule'
      },
      {
        path: 'buttons',
        loadChildren: './views/buttons/buttons.module#ButtonsModule'
      },
      {
        path: 'charts',
        loadChildren: './views/chartjs/chartjs.module#ChartJSModule'
      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'default',
        loadChildren: './views/default/default.module#DefaultModule',
      },
      {
        path: 'block',
        loadChildren: './views/block/block.module#BlockModule',
      },
      {
        path: 'icons',
        loadChildren: './views/icons/icons.module#IconsModule'
      },
      {
        path: 'notifications',
        loadChildren: './views/notifications/notifications.module#NotificationsModule'
      },
      {
        path: 'theme',
        loadChildren: './views/theme/theme.module#ThemeModule'
      },
      {
        path: 'widgets',
        loadChildren: './views/widgets/widgets.module#WidgetsModule'
      },
      {
        path: 'noticia',
        loadChildren: './views/new/new.module#NewModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'evento',
        loadChildren: './views/events/event.module#EventModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'residente',
        loadChildren: './views/resident/resident.module#ResidentModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'incidencia',
        loadChildren: './views/incidence/incidence.module#IncidenceModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'emergencia',
        loadChildren: './views/emergency/emergency.module#EmergencyModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'proyecto',
        loadChildren: './views/project/project.module#ProjectModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'directorio',
        loadChildren: './views/directory/directory.module#DirectoryModule',
        canActivate: [RolGuard, BlockGuard]
      }
      ,
      {
        path: 'pagos',
        loadChildren: './views/payment/payment.module#PaymentModule',
        canActivate: [RolGuard, BlockGuard]
      }
      ,
      {
        path: 'configuracion',
        loadChildren: './views/configuration/configuration.module#ConfigurationModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'usuarios',
        loadChildren: './views/useradmin/useradmin.module#UserAdminModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'unidades',
        loadChildren: './views/house/house.module#HouseModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'commonarea',
        loadChildren: './views/commonarea/commonarea.module#CommonAreaModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'rule',
        loadChildren: './views/rule/rule.module#RuleModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reserve',
        loadChildren: './views/reserve/reserve.module#ReserveModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportpayment',
        loadChildren: './views/reportpayment/reportpayment.module#ReportPaymentModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportdebtors',
        loadChildren: './views/reportdebtors/reportdebtors.module#ReportDebtorsModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportexpense',
        loadChildren: './views/reportexpense/reportexpense.module#ReportExpenseModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportpolicy',
        loadChildren: './views/reportpolicy/reportpolicy.module#ReportPolicyModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportcollectiontable',
        loadChildren: './views/reportcollectiontable/reportcollectiontable.module#ReportCollectionTableModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'expense',
        loadChildren: './views/expense/expense.module#ExpenseModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'invoice',
        loadChildren: './views/invoice/invoice.module#InvoiceModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'listhouse',
        loadChildren: './views/listhouse/listhouse.module#ListHouseModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'account',
        loadChildren: './views/account/account.module#AccountModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'concept',
        loadChildren: './views/concept/concept.module#ConceptModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'extraordinarycharge',
        loadChildren: './views/extraordinarycharge/extraordinarycharge.module#ExtraordinaryChargeModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'invoicebyday',
        loadChildren: './views/invoicebyday/invoicebyday.module#InvoiceByDayModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'manualcharges',
        loadChildren: './views/manualcharge/manualcharge.module#ManualChargeModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'manualpayments',
        loadChildren: './views/manualpayment/manualpayment.module#ManualPaymentModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'cancelpayments',
        loadChildren: './views/cancelpayment/cancelpayment.module#CancelPaymentModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'accountstatements',
        loadChildren: './views/accountstatements/accountstatements.module#AccountStatementsModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'chargesimportexcel',
        loadChildren: './views/chargesimportexcel/chargesimportexcel.module#ChargesImportExcelModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'conceptcategory',
        loadChildren: './views/conceptcategory/conceptcategory.module#ConceptCategoryModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'provider',
        loadChildren: './views/provider/provider.module#ProviderModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'estimates',
        loadChildren: './views/estimate/estimate.module#EstimateModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'invoicebymonth',
        loadChildren: './views/invoicebymonth/invoicebymonth.module#InvoiceByMonthModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'excelbalancereport',
        loadChildren: './views/excelbalancereport/excelbalancereport.module#ExcelBalanceReportModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportestimate',
        loadChildren: './views/reportestimate/reportestimate.module#ReportEstimateModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'car',
        loadChildren: './views/car/car.module#CarModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'invitation',
        loadChildren: './views/invitation/invitation.module#InvitationModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'service',
        loadChildren: './views/service/service.module#ServiceModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'servicecharge',
        loadChildren: './views/servicecharge/servicecharge.module#ServiceChargeModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'servicechargehouse',
        loadChildren: './views/servicechargehouse/servicechargehouse.module#ServiceChargeHouseModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'accountmovement',
        loadChildren: './views/accountmovement/accountmovement.module#AccountMovementModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'unrecognizedpayment',
        loadChildren: './views/unrecognizedpayment/unrecognizedpayment.module#UnrecognizedPaymentModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportbank',
        loadChildren: './views/reportbank/reportbank.module#ReportBankModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reporthousemovements',
        loadChildren: './views/reporthousemovements/reporthousemovements.module#ReportHouseMovementsModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportpaymentdetail',
        loadChildren: './views/reportpaymentdetail/reportpaymentdetail.module#ReportPaymentDetailModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'recharge',
        loadChildren: './views/recharge/recharge.module#RechargeModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'accesscard',
        loadChildren: './views/accesscard/accesscard.module#AccessCardModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'fiscaldata',
        loadChildren: './views/fiscaldata/fiscaldata.module#FiscalDataModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reporthouseandresidents',
        loadChildren: './views/reporthouseandresidents/reporthouseandresidents.module#ReportHouseAndResidentsModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportchargehouse',
        loadChildren: './views/reportchargehouse/reportchargehouse.module#ReportChargeHouseModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportmix',
        loadChildren: './views/reportmix/reportmix.module#ReportMixModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'rolapp',
        loadChildren: './views/rolapp/rolapp.module#RolAppModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'servicetariff',
        loadChildren: './views/servicetariff/servicetariff.module#ServiceTariffModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'servicemeter',
        loadChildren: './views/servicemeter/servicemeter.module#ServiceMeterModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'documentcategory',
        loadChildren: './views/documentcategory/documentcategory.module#DocumentCategoryModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'document',
        loadChildren: './views/document/document.module#DocumentModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'profile',
        loadChildren: './views/profile/profile.module#ProfileModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportinvitation',
        loadChildren: './views/reportinvitation/reportinvitation.module#ReportInvitationModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'rol',
        loadChildren: './views/rol/rol.module#RolModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportstatesale',
        loadChildren: './views/reportstatesale/reportstatesale.module#ReportStateSaleModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'tariff',
        loadChildren: './views/tariff/tariff.module#TariffModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'payouts',
        loadChildren: './views/payouts/payouts.module#PayoutsModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'recurringcharges',
        loadChildren: './views/recurringcharges/recurringcharges.module#RecurringChargesModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'housing',
        loadChildren: './views/housing/housing.module#HousingModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'housingcheckin',
        loadChildren: './views/housingcheckin/housingcheckin.module#HousingCheckinModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'housingreport',
        loadChildren: './views/housingreport/housingreport.module#HousingReportModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reportincidence',
        loadChildren: './views/reportincidence/reportincidence.module#ReportIncidenceModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'pet',
        loadChildren: './views/pet/pet.module#PetModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'access/visitorreport',
        loadChildren: './views/access/visitorreport/visitorreport.module#VisitorReportModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'eventreport',
        loadChildren: './views/eventreport/eventreport.module#EventReportModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'housinghistoricalreport',
        loadChildren: './views/housinghistoricalreport/housinghistoricalreport.module#HousingHistoricalReportModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'areaincidence',
        loadChildren: './views/areaincidence/areaincidence.module#AreaIncidenceModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'typeincidence',
        loadChildren: './views/typeincidence/typeincidence.module#TypeIncidenceModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'reporthousingoccupancy',
        loadChildren: './views/reporthousingoccupancy/reporthousingoccupancy.module#ReportHousingOccupancyModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'incomereport',
        loadChildren: './views/incomereport/incomereport.module#IncomeReportModule',
        canActivate: [RolGuard, BlockGuard]
      },
      {
        path: 'paymentshousetype',
        loadChildren: './views/paymentshousetype/paymentshousetype.module#PaymentsHouseTypeModule',
        canActivate: [RolGuard, BlockGuard]
      },
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
