import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { navItems } from '../_nav';


@Injectable({
  providedIn: 'root'
})
export class RolGuard implements CanActivate {

  private condominium: any;


  constructor(private router: Router) {
    this.condominium = JSON.parse(localStorage.getItem('condominium'));


  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
   // return true;


    if (this.condominium.idRol) {
      const url = route.routeConfig.path;

      const menu = this.getNavItems();
      let item = menu.find(e => e.url === '/' + url);

      // verificar si es en los hijos solo si no se encontro en nodos sin hijos
      if (!item) {
        for (const itemMenu of menu.filter(e => e.url === '#')) {
          if (itemMenu.children && itemMenu.children.length > 0) {
            item = itemMenu.children.find(e => e.url === '/' + url);
            if (item) { // si existe, nos salimos ya
              break;
            }
          }
        }
      }

      // console.log(menu);
      // console.log(url);
       console.log(item);

      // si no tiene rol pasa, o si tiene rol y tiene el item en sus permisos
      if (item || !this.condominium.idRol) {
        return true;
      }

      this.router.navigate(['/default']);
      return false;

    } else {
      return true;
    }


  }

  getNavItems() {


    const navItemsCopy = JSON.parse(JSON.stringify(navItems));

    let items = navItemsCopy.filter(e => this.condominium.actions.includes(e.codeAction) ||
        e.url === '#');

    // recorrer elementos con hijos
    for (const item of items.filter(e => e.url === '#')) {
      const childrenAllowed = item.children.filter(e => this.condominium.actions.includes(e.codeAction));

      item.children = childrenAllowed;
      if (childrenAllowed.length === 0) {
          items = items.filter(e => e !== item);
      }
    }

    return items;

  }

}
