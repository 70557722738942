import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth-service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService,
      public router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {
            if ([401].includes(err.status)) {

                this.authService.logout();
                this.router.navigate(['login']);
            }


            return throwError(err);
            // return throwError(() => err);
        }));
    }
}
