import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { navItems } from '../_nav';

/*
Guard que sirve para evaluar si el condominio esta bloqueado
*/
@Injectable({
  providedIn: 'root'
})
export class BlockGuard implements CanActivate {

  private condominium: any;
  readonly stateBlock = 3;

  constructor(private router: Router) {


  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {


    this.condominium = JSON.parse(localStorage.getItem('condominium'));

    if (Number(this.condominium.idStateCondominium) === this.stateBlock) {

      this.router.navigate(['/block']);

      return false;

    } else {
      return true;
    }


  }


}
